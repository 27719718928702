export const topNav = {
  "API Guidelines": {
    "path": "/guidelines",
    "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/README.md"),
    "public": true
  }
};
export const sideNav = {
  "API Guidelines": {
    "CORE PRINCIPLES": {
      "API design": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/core-principles/api-design.md"),
        "path": "/guidelines/core-principles/api-design",
        "public": true
      },
      "API as a product": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/core-principles/api-as-a-product.md"),
        "path": "/guidelines/core-principles/api-as-a-product",
        "public": true
      },
      "API scope": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/core-principles/api-scope.md"),
        "path": "/guidelines/core-principles/api-scope",
        "public": true
      },
      "Contract first": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/core-principles/contract-first.md"),
        "path": "/guidelines/core-principles/contract-first",
        "public": true
      },
      "Quality standards": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/core-principles/quality-standards.md"),
        "path": "/guidelines/core-principles/quality-standards",
        "public": true
      },
      "Documentation": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/core-principles/documentation.md"),
        "path": "/guidelines/core-principles/documentation",
        "public": true
      }
    },
    "REST GUIDELINES": {
      "Contract": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/contract/README.md"),
        "path": "/guidelines/rest-guidelines/contract",
        "r000005": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/may-provide-api-user-guide.md"),
          "path": "/guidelines/r000005",
          "public": true
        },
        "r000076": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-ensure-implementation-is-compliant-to-openapi-specification.md"),
          "path": "/guidelines/r000076",
          "public": true
        },
        "r000001": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-follow-contract-first-approach.md"),
          "path": "/guidelines/r000001",
          "public": true
        },
        "r000078": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-provide-contact-informaton.md"),
          "path": "/guidelines/r000078",
          "public": true
        },
        "r000046": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-use-tls.md"),
          "path": "/guidelines/r000046",
          "public": true
        },
        "r000004": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-write-apis-using-american-english.md"),
          "path": "/guidelines/r000004",
          "public": true
        },
        "r000002": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/should-develop-ui-first-api-second.md"),
          "path": "/guidelines/r000002",
          "public": true
        },
        "r000006": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/should-involve-technical-writer.md"),
          "path": "/guidelines/r000006",
          "public": true
        },
        "r000003": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/contract/openapi/rules/must-provide-api-specification-using-openapi-for-rest-apis.md"),
          "path": "/guidelines/r000003",
          "public": true
        },
        "r000064": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/contract/openapi/rules/must-use-semantic-versioning-in-openapi-specification-files.md"),
          "path": "/guidelines/r000064",
          "public": true
        },
        "public": true
      },
      "JSON": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/README.md"),
        "path": "/guidelines/rest-guidelines/json",
        "r004090": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-format-enumerations-in-upper-snake-case.md"),
          "path": "/guidelines/r004090",
          "public": true
        },
        "r004030": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-always-return-JSON-objects-as-top-level-data-structure.md"),
          "path": "/guidelines/r004030",
          "public": true
        },
        "r004080": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-represent-enumerations-as-strings.md"),
          "path": "/guidelines/r004080",
          "public": true
        },
        "r004020": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-use-same-semantics-for-null-and-absent-properties.md"),
          "path": "/guidelines/r004020",
          "public": true
        },
        "r004060": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-not-use-null-for-empty-arrays.md"),
          "path": "/guidelines/r004060",
          "public": true
        },
        "r004021": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-omit-optional-properties.md"),
          "path": "/guidelines/r004021",
          "public": true
        },
        "r004040": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-represent-maps-as-objects-with-keys-being-properties.md"),
          "path": "/guidelines/r004040",
          "public": true
        },
        "r004050": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-pluralize-array-names.md"),
          "path": "/guidelines/r004050",
          "public": true
        },
        "r004070": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-support-filtering-of-fields-using-common-query-parameter.md"),
          "path": "/guidelines/r004070",
          "public": true
        },
        "r004010": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-use-camel-case-for-property-names.md"),
          "path": "/guidelines/r004010",
          "public": true
        },
        "r100071": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/canonical-data-types/rules/must-use-common-data-formats.md"),
          "path": "/guidelines/r100071",
          "public": true
        },
        "r100072": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/canonical-data-types/rules/must-use-common-date-and-time-format.md"),
          "path": "/guidelines/r100072",
          "public": true
        },
        "r100074": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/identifiers/rules/should-not-use-lid-to-identify-customers.md"),
          "path": "/guidelines/r100074",
          "public": true
        },
        "r100078": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/identifiers/rules/should-use-customerid-to-identify-customers.md"),
          "path": "/guidelines/r100078",
          "public": true
        },
        "public": true
      },
      "Authorization": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/README.md"),
        "path": "/guidelines/rest-guidelines/authorization",
        "r000051": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/oauth/rules/must-secure-endpoints-with-oauth2.md"),
          "path": "/guidelines/r000051",
          "public": true
        },
        "r000052": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/oauth/rules/must-use-authorization-grant.md"),
          "path": "/guidelines/r000052",
          "public": true
        },
        "r000021": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/oauth/rules/must-use-bearer-authentication.md"),
          "path": "/guidelines/r000021",
          "public": true
        },
        "r000056": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/oauth/rules/should-use-authorization-code-grant-for-confidential-clients.md"),
          "path": "/guidelines/r000056",
          "public": true
        },
        "r000057": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/oauth/rules/should-use-client-credentials-grant-for-machine-to-machine-authorization.md"),
          "path": "/guidelines/r000057",
          "public": true
        },
        "r000047": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/scopes/rules/must-define-and-assign-permissions-by-using-scopes.md"),
          "path": "/guidelines/r000047",
          "public": true
        },
        "r000048": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/scopes/rules/shoud-adhere-to-scope-naming-conventions.md"),
          "path": "/guidelines/r000048",
          "public": true
        },
        "r000075": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/tokens/rules/must-not-validate-audience-of-the-json-web-token.md"),
          "path": "/guidelines/r000075",
          "public": true
        },
        "r000050": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/tokens/rules/must-validate-json-web-token.md"),
          "path": "/guidelines/r000050",
          "public": true
        },
        "r000058": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/authorization/tokens/rules/must_validate_jwt_based_on_keys in_jwks_at_all_endpoints.md"),
          "path": "/guidelines/r000058",
          "public": true
        },
        "public": true
      },
      "HTTP": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/README.md"),
        "path": "/guidelines/rest-guidelines/http",
        "r000059": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/methods/rules/may-use-patch-with-json-patch-test-operation-for-concurrency-control.md"),
          "path": "/guidelines/r000059",
          "public": true
        },
        "r000008": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/methods/rules/must-fulfill-common-method-properties.md"),
          "path": "/guidelines/r000008",
          "public": true
        },
        "r000009": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/methods/rules/should-consider-to-design-post-and-patch-idempotent.md"),
          "path": "/guidelines/r000009",
          "public": true
        },
        "r000007": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/methods/rules/must-use-http-methods-correctly.md"),
          "path": "/guidelines/r000007",
          "public": true
        },
        "r006020": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/caching/rules/must-document-cacheable-get-head-and-post-endpoints.md"),
          "path": "/guidelines/r006020",
          "public": true
        },
        "r006010": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/caching/rules/should-adhere-to-caching-best-practices.md"),
          "path": "/guidelines/r006010",
          "public": true
        },
        "r006030": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/caching/rules/should-avoid-vary-header-for-caching.md"),
          "path": "/guidelines/r006030",
          "public": true
        },
        "r000011": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/status-codes/rules/must-specify-success-and-error-responses.md"),
          "path": "/guidelines/r000011",
          "public": true
        },
        "r000014": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/status-codes/rules/must-use-code-429-with-headers-for-rate-limits.md"),
          "path": "/guidelines/r000014",
          "public": true
        },
        "r000013": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/status-codes/rules/must-use-most-specific-http-status-code.md"),
          "path": "/guidelines/r000013",
          "public": true
        },
        "r000012": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/status-codes/rules/must-use-standard-http-status-codes.md"),
          "path": "/guidelines/r000012",
          "public": true
        },
        "r000010": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/headers/rules/may-use-etag-header-for-caching-resources.md"),
          "path": "/guidelines/r000010",
          "public": true
        },
        "r000080": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/headers/rules/may-use-test-header.md"),
          "path": "/guidelines/r000080",
          "public": true
        },
        "r000081": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/headers/rules/must-forward-test-header.md"),
          "path": "/guidelines/r000081",
          "public": true
        },
        "r000073": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/headers/rules/should-follow-concurrenct-update-pattern-with-if-umodified-since-header.md"),
          "path": "/guidelines/r000073",
          "public": true
        },
        "r000072": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/headers/rules/should-not-use-last-modified-and-if-unmodified-since-headers-for-concurrency-control.md"),
          "path": "/guidelines/r000072",
          "public": true
        },
        "r000060": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/http/headers/rules/should-use-etag-together-with-if-match-if-none-match-header-for-concurrrency-control.md"),
          "path": "/guidelines/r000060",
          "public": true
        },
        "public": true
      },
      "Resources": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/README.md"),
        "path": "/guidelines/rest-guidelines/resources",
        "r000045": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/embedded-resources/rules/must-use-hal-format-for-embedded-resources.md"),
          "path": "/guidelines/r000045",
          "public": true
        },
        "r000041": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/embedded-resources/rules/should-embed-subresources.md"),
          "path": "/guidelines/r000041",
          "public": true
        },
        "r000043": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/embedded-resources/rules/should-read-embedded-resources-instead-of-traversing-links.md"),
          "path": "/guidelines/r000043",
          "public": true
        },
        "r000063": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/embedded-resources/rules/should-support-optional-embedding-of-subresources.md"),
          "path": "/guidelines/r000063",
          "public": true
        },
        "r100023": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-support-pagination-for-collection-resources.md"),
          "path": "/guidelines/r100023",
          "public": true
        },
        "r100025": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-provide-metadata-for-offset-based-pagination.md"),
          "path": "/guidelines/r100025",
          "public": true
        },
        "r100030": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-use-common-sorting-query-parameter.md"),
          "path": "/guidelines/r100030",
          "public": true
        },
        "r100024": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-use-common-paging-query-parameters.md"),
          "path": "/guidelines/r100024",
          "public": true
        },
        "r100021": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-use-hal-format-for-collection-resource.md"),
          "path": "/guidelines/r100021",
          "public": true
        },
        "r100041": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-use-json-for-advanced-querying-and-filtering.md"),
          "path": "/guidelines/r100041",
          "public": true
        },
        "r100020": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-use-plural-for-collection-resources.md"),
          "path": "/guidelines/r100020",
          "public": true
        },
        "r100031": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/must-use-query-parameters-for-basic-search-or-filtering.md"),
          "path": "/guidelines/r100031",
          "public": true
        },
        "r100039": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/collection-resources/rules/should-define-default-and-max-page-size.md"),
          "path": "/guidelines/r100039",
          "public": true
        },
        "r000015": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-avoid-actions-as-resource-names.md"),
          "path": "/guidelines/r000015",
          "public": true
        },
        "r000018": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-follow-a-logical-order.md"),
          "path": "/guidelines/r000018",
          "public": true
        },
        "r000020": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-not-end-uri-with-a-trailing-slash.md"),
          "path": "/guidelines/r000020",
          "public": true
        },
        "r000049": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-stick-to-conventional-query-parameters.md"),
          "path": "/guidelines/r000049",
          "public": true
        },
        "r000062": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-support-lists-for-multiple-values-of-the-same-parameter.md"),
          "path": "/guidelines/r000062",
          "public": true
        },
        "r000022": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-use-camelcase-for-query-parameters.md"),
          "path": "/guidelines/r000022",
          "public": true
        },
        "r000019": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-use-forward-slash-to-indicate-hierarchical-relationships.md"),
          "path": "/guidelines/r000019",
          "public": true
        },
        "r000023": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-use-kebabcase-for-uris.md"),
          "path": "/guidelines/r000023",
          "public": true
        },
        "r000017": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-use-lowercase-letters-in-uris.md"),
          "path": "/guidelines/r000017",
          "public": true
        },
        "r000016": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-use-nouns-to-represent-resources.md"),
          "path": "/guidelines/r000016",
          "public": true
        },
        "r000024": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/must-use-url-friendly-resource-identifiers.md"),
          "path": "/guidelines/r000024",
          "public": true
        },
        "r100077": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/resources/naming-conventions/rules/should-not-use-external-identifier-as-primary-resource-identifier.md"),
          "path": "/guidelines/r100077",
          "public": true
        },
        "public": true
      },
      "Hypermedia": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/README.md"),
        "path": "/guidelines/rest-guidelines/hypermedia",
        "r000032": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/maturity-level/rules/must-implement-rest-maturity-level-2.md"),
          "path": "/guidelines/r000032",
          "public": true
        },
        "r000033": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/maturity-level/rules/must-implement-rest-maturity-level-3-for-transitional-apis.md"),
          "path": "/guidelines/r000033",
          "public": true
        },
        "r000036": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/maturity-level/rules/must-use-hal-to-implement-rest-maturity-level-3.md"),
          "path": "/guidelines/r000036",
          "public": true
        },
        "r100065": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-contain-deprecation-attribute.md"),
          "path": "/guidelines/r100065",
          "public": true
        },
        "r000042": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-contain-links-for-embedded-resources.md"),
          "path": "/guidelines/r000042",
          "public": true
        },
        "r000061": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-contain-separate-id-field-in-the-payload.md"),
          "path": "/guidelines/r000061",
          "public": true
        },
        "r100034": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-not-use-link-headers-for-json-representations.md"),
          "path": "/guidelines/r100034",
          "public": true
        },
        "r100033": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-provide-conventional-hyperlinks.md"),
          "path": "/guidelines/r100033",
          "public": true
        },
        "r000044": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-support-forwarded-header.md"),
          "path": "/guidelines/r000044",
          "public": true
        },
        "r100026": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-support-hypermedia-controls-in-collection-resources.md"),
          "path": "/guidelines/r100026",
          "public": true
        },
        "r100064": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/should-contain-link-title-attribute.md"),
          "path": "/guidelines/r100064",
          "public": true
        },
        "r100032": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/must-use-absolute-urls.md"),
          "path": "/guidelines/r100032",
          "public": true
        },
        "r100028": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/links/rules/should-provide-hypermedia-links-in-embedded-documents.md"),
          "path": "/guidelines/r100028",
          "public": true
        },
        "r100063": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/link-relation-types/rules/must-document-link-cardinality.md"),
          "path": "/guidelines/r100063",
          "public": true
        },
        "r100035": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/link-relation-types/rules/must-prefer-existing-custom-link-relation-types.md"),
          "path": "/guidelines/r100035",
          "public": true
        },
        "r100036": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/link-relation-types/rules/must-prefer-iana-registered-link-relation-types.md"),
          "path": "/guidelines/r100036",
          "public": true
        },
        "r100038": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/link-relation-types/rules/must-use-curied-link-relation-types.md"),
          "path": "/guidelines/r100038",
          "public": true
        },
        "r100037": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/hypermedia/link-relation-types/rules/must-use-absolute-uris-for-custom-link-relation-types.md"),
          "path": "/guidelines/r100037",
          "public": true
        },
        "public": true
      },
      "Errors": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/errors/README.md"),
        "path": "/guidelines/rest-guidelines/errors",
        "r000038": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/errors/error-handling/rules/must-use-extension-for-input-validation-errors.md"),
          "path": "/guidelines/r000038",
          "public": true
        },
        "r000034": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/errors/error-handling/rules/must-use-problem-json-as-error-response-format.md"),
          "path": "/guidelines/r000034",
          "public": true
        },
        "r000040": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/errors/error-handling/rules/may-add-custom-extensions-by-defining-a-problem-type.md"),
          "path": "/guidelines/r000040",
          "public": true
        },
        "r000037": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/errors/error-handling/rules/should-use-existing-problem-types.md"),
          "path": "/guidelines/r000037",
          "public": true
        },
        "public": true
      },
      "Compatibility": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/README.md"),
        "path": "/guidelines/rest-guidelines/compatibility",
        "r000027": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/must-not-break-backward-compatibility.md"),
          "path": "/guidelines/r000027",
          "public": true
        },
        "r000028": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/should-prefer-compatible-extensions.md"),
          "path": "/guidelines/r000028",
          "public": true
        },
        "r000035": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/should-use-extensible-enums.md"),
          "path": "/guidelines/r000035",
          "public": true
        },
        "r000029": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/must-prepare-consumers-to-accept-compatible-api-extensions.md"),
          "path": "/guidelines/r000029",
          "public": true
        },
        "r000066": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/deprecation/rules/must-collect-external-partner-consent-on-deprecation-time-span.md"),
          "path": "/guidelines/r000066",
          "public": true
        },
        "r000054": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/deprecation/rules/must-obtain-approval-of-consumers-before-api-shutdown.md"),
          "path": "/guidelines/r000054",
          "public": true
        },
        "r000067": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/deprecation/rules/must-reflect-deprecation-in-api-specifications.md"),
          "path": "/guidelines/r000067",
          "public": true
        },
        "r000079": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/client-behavior/rules/must-send-unknown-fields-in-put-requests.md"),
          "path": "/guidelines/r000079",
          "public": true
        },
        "r100066": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/versioning-of-incompatible-changes/rules/must-use-absolute-profile-uris.md"),
          "path": "/guidelines/r100066",
          "public": true
        },
        "r000065": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/versioning-of-incompatible-changes/rules/must-use-profiles-for-versioning.md"),
          "path": "/guidelines/r000065",
          "public": true
        },
        "r000026": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/versioning-of-incompatible-changes/rules/should-not-use-uri-versioning.md"),
          "path": "/guidelines/r000026",
          "public": true
        },
        "r000068": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/deprecation-of-http-apis/rules/must-monitor-usage-of-deprecated-api-scheduled-for-sunset.md"),
          "path": "/guidelines/r000068",
          "public": true
        },
        "r000069": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/deprecation-of-http-apis/rules/should-add-deprecation-and-sunset-header-to-responses.md"),
          "path": "/guidelines/r000069",
          "public": true
        },
        "r000070": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/rest/compatibility/deprecation-of-http-apis/rules/should-add-monitoring-for-deprecation-and-sunset-header.md"),
          "path": "/guidelines/r000070",
          "public": true
        },
        "public": true
      }
    },
    "EVENT GUIDELINES": {
      "Contract": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/contract/README.md"),
        "path": "/guidelines/event-guidelines/contract",
        "r000076": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-ensure-implementation-is-compliant-to-openapi-specification.md"),
          "path": "/guidelines/r000076",
          "public": true
        },
        "r000001": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-follow-contract-first-approach.md"),
          "path": "/guidelines/r000001",
          "public": true
        },
        "r000005": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/may-provide-api-user-guide.md"),
          "path": "/guidelines/r000005",
          "public": true
        },
        "r000078": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-provide-contact-informaton.md"),
          "path": "/guidelines/r000078",
          "public": true
        },
        "r000046": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-use-tls.md"),
          "path": "/guidelines/r000046",
          "public": true
        },
        "r000004": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/must-write-apis-using-american-english.md"),
          "path": "/guidelines/r000004",
          "public": true
        },
        "r000002": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/should-develop-ui-first-api-second.md"),
          "path": "/guidelines/r000002",
          "public": true
        },
        "r000006": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/basics/rules/should-involve-technical-writer.md"),
          "path": "/guidelines/r000006",
          "public": true
        },
        "r200005": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/contract/asyncapi/rules/must-provide-api-specification-using-asyncapi-for-asyncrhonous-apis.md"),
          "path": "/guidelines/r200005",
          "public": true
        },
        "r200008": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/contract/asyncapi/rules/must-use-semantic-versioning-for-asyncapi-specification.md"),
          "path": "/guidelines/r200008",
          "public": true
        },
        "public": true
      },
      "JSON": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/README.md"),
        "path": "/guidelines/event-guidelines/json",
        "r004090": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-format-enumerations-in-upper-snake-case.md"),
          "path": "/guidelines/r004090",
          "public": true
        },
        "r004080": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-represent-enumerations-as-strings.md"),
          "path": "/guidelines/r004080",
          "public": true
        },
        "r004030": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-always-return-JSON-objects-as-top-level-data-structure.md"),
          "path": "/guidelines/r004030",
          "public": true
        },
        "r004020": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/must-use-same-semantics-for-null-and-absent-properties.md"),
          "path": "/guidelines/r004020",
          "public": true
        },
        "r004060": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-not-use-null-for-empty-arrays.md"),
          "path": "/guidelines/r004060",
          "public": true
        },
        "r004021": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-omit-optional-properties.md"),
          "path": "/guidelines/r004021",
          "public": true
        },
        "r004050": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-pluralize-array-names.md"),
          "path": "/guidelines/r004050",
          "public": true
        },
        "r004070": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-support-filtering-of-fields-using-common-query-parameter.md"),
          "path": "/guidelines/r004070",
          "public": true
        },
        "r004040": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-represent-maps-as-objects-with-keys-being-properties.md"),
          "path": "/guidelines/r004040",
          "public": true
        },
        "r004010": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/naming-conventions/rules/should-use-camel-case-for-property-names.md"),
          "path": "/guidelines/r004010",
          "public": true
        },
        "r100071": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/canonical-data-types/rules/must-use-common-data-formats.md"),
          "path": "/guidelines/r100071",
          "public": true
        },
        "r100072": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/canonical-data-types/rules/must-use-common-date-and-time-format.md"),
          "path": "/guidelines/r100072",
          "public": true
        },
        "r100074": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/identifiers/rules/should-not-use-lid-to-identify-customers.md"),
          "path": "/guidelines/r100074",
          "public": true
        },
        "r100078": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/json/identifiers/rules/should-use-customerid-to-identify-customers.md"),
          "path": "/guidelines/r100078",
          "public": true
        },
        "public": true
      },
      "Concepts": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/concepts/README.md"),
        "path": "/guidelines/event-guidelines/concepts",
        "public": true
      },
      "Format": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/README.md"),
        "path": "/guidelines/event-guidelines/format",
        "r200003": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/cloudevents/rules/may-provide-sequence-context-attribute.md"),
          "path": "/guidelines/r200003",
          "public": true
        },
        "r200009": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/cloudevents/rules/must-follow-naming-schema-for-type-context-attribute.md"),
          "path": "/guidelines/r200009",
          "public": true
        },
        "r200013": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/cloudevents/rules/must-provide-datacontenttype-context-attribute-if-media-type-is-defined.md"),
          "path": "/guidelines/r200013",
          "public": true
        },
        "r200011": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/cloudevents/rules/must-provide-event-time-in-utc.md"),
          "path": "/guidelines/r200011",
          "public": true
        },
        "r200010": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/cloudevents/rules/must-provide-meaningful-source-context-attribute.md"),
          "path": "/guidelines/r200010",
          "public": true
        },
        "r200012": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/cloudevents/rules/must-use-binary-content-mode.md"),
          "path": "/guidelines/r200012",
          "public": true
        },
        "r200001": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/cloudevents/rules/must-use-cloudevents-format-to-encode-events.md"),
          "path": "/guidelines/r200001",
          "public": true
        },
        "r200004": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/naming-conventions/rules/must-name-events-in-past-tense.md"),
          "path": "/guidelines/r200004",
          "public": true
        },
        "r200023": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/test-extension/rules/may-ignore-events-flagged-with-test.md"),
          "path": "/guidelines/r200023",
          "public": true
        },
        "r200021": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/test-extension/rules/may-use-test-extension.md"),
          "path": "/guidelines/r200021",
          "public": true
        },
        "r200022": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/format/test-extension/rules/must-forward-test-context-attribute.md"),
          "path": "/guidelines/r200022",
          "public": true
        },
        "public": true
      },
      "Semantics": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/semantics/README.md"),
        "path": "/guidelines/event-guidelines/semantics",
        "r200002": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/semantics/event-structure/rules/must-prepare-consumers-to-consume-events-idempotently.md"),
          "path": "/guidelines/r200002",
          "public": true
        },
        "r200018": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/semantics/event-structure/rules/should-not-publish-data-from-other-business-domains.md"),
          "path": "/guidelines/r200018",
          "public": true
        },
        "r200007": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/semantics/event-structure/rules/must-enable-consumers-to-process-domain-events-in-order.md"),
          "path": "/guidelines/r200007",
          "public": true
        },
        "r000082": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/semantics/event-structure/rules/should-send-durable-event-ids.md"),
          "path": "/guidelines/r000082",
          "public": true
        },
        "public": true
      },
      "Kafka": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/kafka/README.md"),
        "path": "/guidelines/event-guidelines/kafka",
        "r200019": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/kafka/topics/rules/must-choose-adequate-retention-time.md"),
          "path": "/guidelines/r200019",
          "public": true
        },
        "r200016": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/kafka/topics/rules/must-write-new-incompatible-version-of-data-events-into-separate-topic.md"),
          "path": "/guidelines/r200016",
          "public": true
        },
        "r200017": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/kafka/topics/rules/must-write-new-incompatible-version-of-domain-events-into-same-topic.md"),
          "path": "/guidelines/r200017",
          "public": true
        },
        "r200006": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/kafka/topics/rules/must-follow-kafka-topic-naming-convention.md"),
          "path": "/guidelines/r200006",
          "public": true
        },
        "public": true
      },
      "Compatibility": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/compatibility/README.md"),
        "path": "/guidelines/event-guidelines/compatibility",
        "r000027": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/must-not-break-backward-compatibility.md"),
          "path": "/guidelines/r000027",
          "public": true
        },
        "r000029": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/must-prepare-consumers-to-accept-compatible-api-extensions.md"),
          "path": "/guidelines/r000029",
          "public": true
        },
        "r000028": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/should-prefer-compatible-extensions.md"),
          "path": "/guidelines/r000028",
          "public": true
        },
        "r000066": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/deprecation/rules/must-collect-external-partner-consent-on-deprecation-time-span.md"),
          "path": "/guidelines/r000066",
          "public": true
        },
        "r000035": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/compatible-changes/rules/should-use-extensible-enums.md"),
          "path": "/guidelines/r000035",
          "public": true
        },
        "r000054": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/deprecation/rules/must-obtain-approval-of-consumers-before-api-shutdown.md"),
          "path": "/guidelines/r000054",
          "public": true
        },
        "r000067": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/global/compatibility/deprecation/rules/must-reflect-deprecation-in-api-specifications.md"),
          "path": "/guidelines/r000067",
          "public": true
        },
        "r200014": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/compatibility/versioning/rules/must-change-type-context-attribute-to-indicate-incompatible-changes.md"),
          "path": "/guidelines/r200014",
          "public": true
        },
        "r200015": {
          "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/api-guidelines/async/compatibility/versioning/rules/must-filter-consumed-events-by-type.md"),
          "path": "/guidelines/r200015",
          "public": true
        },
        "public": true
      }
    },
    "REVISION HISTORY": {
      "Changelog": {
        "file": () => import("/home/runner/work/ottoapi_portal/ottoapi_portal/node_modules/@otto-de/api-guidelines/changes/changelog.md"),
        "path": "/guidelines/changelog",
        "public": true
      }
    }
  }
};